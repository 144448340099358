
    @font-face {
    font-family: 'SFPRO';
    font-style: normal;
    font-weight: normal;
    src :url("../public/SFPRODISPLAYREGULAR.OTF");
    }
.Background{
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color: #11190C;

}
*, body, html {
    background-color:#11190C;
    font-family: 'SFPRO', sans-serif !important;
    overflow:hidden;
}
